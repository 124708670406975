<template>
    <b-card>
        <b-row>
            <b-col sm="12" style="margin-top: 5px">
                <full-calendar ref="calendar" :event-sources="eventSources" @event-selected="eventSelected" @event-created="eventCreated" :config="config"></full-calendar>
            </b-col>
        </b-row>
        <b-modal id="modal1" :title="'Настройка даты ' + form.date" hide-footer ref="myModalRef">
            <b-form-group>
                <label>Выберите тип для даты</label>
                <b-select v-model="form.type">
                    <option value="1">Выходной день</option>
                    <option value="2">Предпраздничный день</option>
                    <option value="3">Рабочий день</option>
                </b-select>
            </b-form-group>
            <b-form @submit.prevent="createEvent">
                <b-button type="submit" variant="primary" :disabled="!form.type">Сохранить</b-button>
            </b-form>
        </b-modal>
    </b-card>
</template>

<script>
import { FullCalendar } from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar/dist/locale/ru';
import axios from 'axios';

export default {
    components: {
        FullCalendar,
    },
    metaInfo: {
        title: 'Календарь',
    },
    data() {
        return {
            eventSources: [
                {
                    events(start, end, timezone, callback) {
                        let payload = { start, end };
                        axios.get('/calendar', { params: payload }).then((response) => {
                            callback(response.data);
                        });
                    },
                },
            ],
            form: {
                type: null,
                date: '',
            },
            config: {
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: '',
                },
                eventClick: (event) => {
                    this.selected = event;
                },
                locale: 'ru',
                defaultView: 'month',
                editable: true,
                selectable: true,
                selectHelper: true,
                bootstrapStyling: true,
            },
            selected: {},
        };
    },
    methods: {
        refreshEvents() {
            this.$refs.calendar.$emit('refetch-events');
        },
        eventSelected(event) {
            this.selected = event;
        },
        eventCreated(...test) {
            this.form.date = test[0].start.format();
            this.form.type = null;
            this.$refs['myModalRef'].show();
        },
        createEvent() {
            this.$refs['myModalRef'].hide();
            axios.post('/calendar', this.form).then(() => {
                this.form.date = '';
                this.form.type = null;
                this.refreshEvents();
            });
        },
    },
};
</script>
